import React from "react";
import logo from "./wcologo.png";

const Holding = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img src={logo} style={{ width: 250, height: "auto" }} alt="" />
      <p>
        Results for the 2024 West Coast Open will be available here once the event begins.
      </p>
    </div>
  );
};

export default Holding;
