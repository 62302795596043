import React from 'react';
import Home from './components/home';
import Holding from './components/holding';

function App() {
  return (
    <Holding />
  );
}

export default App;
